const ctaBannerFormToggleWrapper = document.querySelector('.cta-banner__form-toggle-wrapper');
const ctaBannerFormToggles = Array.from(document.querySelectorAll<HTMLElement>('.cta-banner__form-toggle'));
const ctaBannerForm = document.querySelector<HTMLElement>('.cta-banner__form');
const ctaBannerFormContainer = document.querySelector<HTMLElement>('.cta-banner__form > .container');
const submitButton = document.querySelector<HTMLElement>('.cta-banner__form input[type="submit"]');

if (ctaBannerFormToggles && ctaBannerForm) {
    const setFormHeight = () => {
      const containerHeight = ctaBannerFormContainer.getBoundingClientRect().height;
      ctaBannerForm.style.height = containerHeight + 'px';
    }

    const setFormHeightErrors = () => {
      const containerHeight = ctaBannerFormContainer.getBoundingClientRect().height + 200;
      ctaBannerForm.style.height = containerHeight + 'px';
    }

    const handleFormToggle = () => {
        ctaBannerFormToggleWrapper.classList.toggle('cta-banner__form--open');
        ctaBannerForm.classList.toggle('cta-banner__form--active');

        if (ctaBannerForm.classList.contains('cta-banner__form--active')) {
          setFormHeight();
        } else {
          ctaBannerForm.style.height = "0px";
        }
    };

  ctaBannerFormToggles.forEach((trigger) => trigger.addEventListener('click', handleFormToggle));
  if (submitButton) {
    submitButton.addEventListener('click', (e) => {
      setFormHeightErrors();
    });
  }
}
