import Masonry from 'masonry-layout';
import Swiper, { Scrollbar } from 'swiper';

Swiper.use([Scrollbar]);

const grid = document.querySelector('.category-grid');
const gridCutOff = 645;
const windowWidth = window.innerWidth;

if (grid && windowWidth > gridCutOff) {
    var msnry = new Masonry(grid, {
        itemSelector: '.category-grid__item',
        columnWidth: '.grid-sizer',
    });
} else if (grid && windowWidth <= gridCutOff) {
    const categoryGridOptions = {
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            snapOnRelease: true,
            hide: false,
        },
        spaceBetween: 20,
        breakpoints: {
            0: {
                slidesPerView: 1.6,
            },
        },
    };

    const swiper = new Swiper('.category-grid', categoryGridOptions);
}
