const pageUrlFields = document.querySelectorAll('.js-page-url-field') as NodeListOf<HTMLInputElement>;
if (pageUrlFields.length > 0) {
    pageUrlFields.forEach(pageUrlField => {
        pageUrlField.value = window.location.pathname;
        const pageUrlContainer = pageUrlField.closest('.form-field');
        if (pageUrlContainer) {
            pageUrlContainer.classList.remove('form-field');
            pageUrlContainer.classList.add('form-field--hidden');
        }
    });
}
