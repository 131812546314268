import Swiper, { Scrollbar } from 'swiper';

Swiper.use([Scrollbar]);

const gallerySwiper = new Swiper('.media-gallery__items', {
    slidesPerView: 3,
    spaceBetween: 30,
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        snapOnRelease: true,
        hide: false,
    },
    breakpoints: {
        0: {
            slidesPerView: 1.25,
            spaceBetween: 10,
        },
        480: {
            slidesPerView: 1.5,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
            scrollbar: {
                dragSize: 50,
            },
        },
        1200: {
            slidesPerView: 3.5,
            spaceBetween: 30,
            scrollbar: {
                dragSize: 330,
            },
        },
    },
});

const filterSwiper = new Swiper('.media-gallery__filters', {
    loop: false,
    spaceBetween: 0,
    scrollbar: {
        el: '.filter-swiper-scrollbar',
    },
    breakpoints: {
        320: {
            slidesPerView: 3,
        },
        768: {
            slidesPerView: 6,
        },
        1200: {
            slidesPerView: 'auto',
        },
    },
});

const modalContainer = document.querySelector('.media-gallery__modal-container');

if (modalContainer) {
    const modalBody = document.querySelector('.media-gallery__modal-body') as HTMLDivElement;
    const modalBackdrop = document.querySelector('.media-gallery__modal-backdrop') as HTMLDivElement;
    const modalClose = document.querySelector('.media-gallery__modal-close') as HTMLDivElement;
    const modalItems = document.querySelectorAll('.media-gallery__item') as NodeListOf<HTMLDivElement>;
    const modalActiveClass = 'media-gallery__modal-container--show';

    const handleModalOpen = (e) => {
        const modalType = e.target.getAttribute('data-modal-type');
        modalBody.innerHTML = '';

        if (modalType === 'image') {
            const newImage = document.createElement('img');

            newImage.src = e.originalTarget.dataset.src ? e.originalTarget.dataset.src : e.originalTarget.src;
            modalBody.appendChild(newImage);
        } else {
            const newVideo = document.createElement('video');

            newVideo.src = e.originalTarget.dataset.src ? e.originalTarget.dataset.src : e.originalTarget.src;
            newVideo.autoplay = true;
            modalBody.appendChild(newVideo);
        }
        modalContainer.classList.add(modalActiveClass);

        e.preventDefault();
    };

    const handleModalClose = (e) => {
        modalContainer.classList.remove(modalActiveClass);
    };

    modalItems.forEach((modalItem) => {
        modalItem.addEventListener('click', (e: Event) => handleModalOpen(e));
    });

    modalClose.addEventListener('click', (e: Event) => handleModalClose(e));
    modalBackdrop.addEventListener('click', (e: Event) => handleModalClose(e));
}

const filters = document.querySelectorAll('.media-gallery__filter');

filters.forEach((filter) => {
    filter.addEventListener('click', function () {
        filters.forEach((f) => f.classList.remove('active'));

        filter.classList.add('active');

        const selectedFilter = filter.getAttribute('data-filter');

        const allItems = document.querySelectorAll(`.swiper-wrapper .swiper-slide`);

        if (selectedFilter == 'All') {
            allItems.forEach((item: HTMLElement) => item.classList.remove('hide'));
        } else {
            allItems.forEach((item: HTMLElement) => {
                const thisFilters = item.dataset.filter.split(',');

                return thisFilters.indexOf(selectedFilter) > -1
                    ? item.classList.remove('hide')
                    : item.classList.add('hide');
            });
        }
    });
});
