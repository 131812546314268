var jumpCtas = document.querySelectorAll('a[data-jump-to-form="True"]');
if (jumpCtas.length > 0) {
    var jumpForm = document.querySelector('[data-ktc-page-form="True"]');
    var isEnquiryForm = jumpForm !== null && jumpForm.parentElement.parentElement.parentElement.id === 'enquiry-form';
    var jumpId = jumpForm === null ? '' : isEnquiryForm ? 'enquiry-form' : '#';
    var headerRect = document.querySelector('.header__main').getBoundingClientRect();
    for (var i = 0; i < jumpCtas.length; i++) {
        if (jumpId === '') {
            jumpCtas[i].remove();
        } else {
            jumpCtas[i].setAttribute('href', '#' + jumpId);
            if (!isEnquiryForm) {
                jumpCtas[i].addEventListener('click', (e) => {
                    e.preventDefault();
                    jumpForm.scrollIntoView();
                    window.scrollBy(0, -headerRect.height - 20);
                });
            }
        }
    }
}

const brochureModal = document.querySelector('.hero__modal') as HTMLDivElement;
if (brochureModal) {
    const brochureCtas = document.querySelectorAll('a[data-open-brochure-modal="True"]');
    if (brochureCtas.length > 0) {
        const closeModal = document.querySelector('.hero__modal-close') as HTMLDivElement;
        const modalBackdrop = document.querySelector('.hero__modal-backdrop') as HTMLDivElement;

        const handleModalOpen = (e) => {
            e.preventDefault();
            brochureModal.style.opacity = '1';
            brochureModal.style.visibility = 'visible';
        };

        const handleModalClose = (e) => {
            e.preventDefault();
            brochureModal.style.opacity = '0';
            brochureModal.style.visibility = 'hidden';
        };

        if (closeModal) {
            closeModal.addEventListener('click', (e: Event) => handleModalClose(e));
        }
        if (modalBackdrop) {
            modalBackdrop.addEventListener('click', (e: Event) => handleModalClose(e));
        }
        for (var i = 0; i < brochureCtas.length; i++) {
            brochureCtas[i].addEventListener('click', handleModalOpen);
        }
    }
}
