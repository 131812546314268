const contactForm = document.querySelector('.contact-form');

if (contactForm) {
    const formTriggerActiveClass = 'contact-form__nav-item--active';
    const formActiveClass = 'contact-form__single--active';
    const formTriggers = document.querySelectorAll('.contact-form__nav-item');
    const handleFormTriggerClick = (event) => {
      const _this = event.currentTarget;
      const targetForm = _this.dataset.formTarget;

      const formsEls = document.querySelectorAll('.contact-form__single');
      const targetFormEl = document.querySelector(`[data-form-id="${targetForm}"]`);

      formsEls.forEach((form) => form.classList.remove(formActiveClass));
      targetFormEl.classList.add(formActiveClass);

      formTriggers.forEach((trigger) => trigger.classList.remove(formTriggerActiveClass));
      _this.classList.add(formTriggerActiveClass);
    };

    formTriggers.forEach((trigger) => trigger.addEventListener('click', handleFormTriggerClick));
}

