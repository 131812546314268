import simpleParallax from 'simple-parallax-js';
import isInViewport from '../../../src/ts/libs/isInViewport';
import '../../../src/js/libs/odometer.min';

var image = document.getElementsByClassName('stat-block__wrapper-bg');

if (image) {
    new simpleParallax(image, {
        delay: 0.3,
        transition: 'cubic-bezier(0,0,0,1)',
        orientation: 'down',
    });

    const mountStats = () => {
        const statsWrapper = document.querySelector('.stat-block__wrapper');
        const stats = Array.from(document.querySelectorAll<HTMLElement>('.stat-block__stat'));

        if (isInViewport(statsWrapper)) {
            stats.forEach((s) => {
                setTimeout(function () {
                    s.innerHTML = s.dataset.value;
                }, 500);
            });
        }
    }

    window.addEventListener('scroll', mountStats);
    mountStats()
}
