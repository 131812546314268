import simpleParallax from 'simple-parallax-js';
import '../../../src/js/libs/odometer.min';

var image = document.getElementsByClassName('countdown__wrapper-bg');

if (image.length > 0) {
    new simpleParallax(image, {
        delay: 0.3,
        transition: 'cubic-bezier(0,0,0,1)',
        orientation: 'down',
    });

    const countdownWrapper = document.querySelector<HTMLDivElement>('.countdown__wrapper'),
        dayFigure = document.querySelector<HTMLDivElement>('.countdown__fig--days'),
        hoursFigure = document.querySelector<HTMLDivElement>('.countdown__fig--hours'),
        minsFigure = document.querySelector<HTMLDivElement>('.countdown__fig--minutes'),
        second = 1000,
        minute = second * 60,
        hour = minute * 60,
        day = hour * 24;

    if (countdownWrapper) {
        const countDown = new Date(countdownWrapper.dataset.countdown).getTime();

        const countDownTimer = setInterval(function () {
            const now = new Date().getTime(),
                distance = countDown - now;

            dayFigure.innerText = '' + Math.floor(distance / day);
            hoursFigure.innerText = '' + Math.floor((distance % day) / hour);
            minsFigure.innerText = '' + Math.floor((distance % hour) / minute);

            if (distance < 0) {
                document.getElementById('countdown').style.display = 'none';
                document.getElementById('content').style.display = 'block';
                clearInterval(countDownTimer);
            }
        }, 0);
    }
}
