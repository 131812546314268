import axios from 'axios';

const thankYouDownloadBtn = document.querySelector('.thank-you-download-btn') as HTMLDivElement;

if (thankYouDownloadBtn) {

    const thankYouDownloadBtnLink = thankYouDownloadBtn.dataset.downloadLink;
    const thankYouDownloadFileName = thankYouDownloadBtn.dataset.downloadName;

    if (thankYouDownloadBtnLink && thankYouDownloadFileName) {
        thankYouDownloadBtn.addEventListener('click', function () { 
            axios({
                url: thankYouDownloadBtnLink,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', thankYouDownloadFileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
        });
    }
}