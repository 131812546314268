import Swiper, { Navigation } from 'swiper/core';
Swiper.use([Navigation]);

const timelineSwiper = new Swiper('.timeline-carousel__cards', {
    slidesPerView: 2.5,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        0: {
            slidesPerView: 1
        },
        768: {
            slidesPerView: 1.5
        },
        1200: {
            slidesPerView: 2
        },
        1440: {
            slidesPerView: 2.5
        }
    },
});