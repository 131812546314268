function hideFormField(formField) {
    const formFieldContainer = formField.closest('.form-field');
    if (formFieldContainer) {
        formFieldContainer.classList.remove('form-field');
        formFieldContainer.classList.add('form-field--hidden');
    }
}

const pageUrlFields = document.querySelectorAll('.js-page-url-field') as NodeListOf<HTMLInputElement>;
if (pageUrlFields.length > 0) {
    pageUrlFields.forEach((pageUrlField) => {
        pageUrlField.value = window.location.pathname;
        hideFormField(pageUrlField);
    });
}
