const locationMap = document.getElementById('map');

if (locationMap) {
    const locationItems = Array.from(document.querySelectorAll<HTMLElement>('.location__item'));
    const google = (window as any).google;
    let pins = [],
        loop = 0;
    const locations:Array<string> = JSON.parse(locationMap.dataset.pins);

    locations.forEach((location) => {
        pins.push([
            'icon' + loop,
            location[0],
            location[1],
            location[2]
        ])
        loop++;
    });

    let map,
        markers = [];

    const icon = '/assets/img/map-pin.png';

    async function initMap() {
        const { Map } = await google.maps.importLibrary('maps');
        const mapOptions = {
            center: { lat: 54.6272987, lng: -6.7717346 },
            zoom: 8,
            disableDefaultUI: true,
            zoomControl: true,
            scrollwheel: true,
            styles: [
                { featureType: 'all', elementType: 'geometry.fill', stylers: [{ weight: '2.00' }] },
                { featureType: 'all', elementType: 'geometry.stroke', stylers: [{ color: '#9c9c9c' }] },
                { featureType: 'all', elementType: 'labels.text', stylers: [{ visibility: 'on' }] },
                { featureType: 'landscape', elementType: 'all', stylers: [{ color: '#f2f2f2' }] },
                { featureType: 'landscape', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
                { featureType: 'landscape.man_made', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }] },
                { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
                { featureType: 'road', elementType: 'all', stylers: [{ saturation: -100 }, { lightness: 45 }] },
                { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#eeeeee' }] },
                { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#7b7b7b' }] },
                { featureType: 'road', elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] },
                { featureType: 'road.highway', elementType: 'all', stylers: [{ visibility: 'simplified' }] },
                { featureType: 'road.arterial', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
                { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
                { featureType: 'water', elementType: 'all', stylers: [{ color: '#46bcec' }, { visibility: 'on' }] },
                { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: '#c8d7d4' }] },
                { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#070707' }] },
                { featureType: 'water', elementType: 'labels.text.stroke', stylers: [{ color: '#ffffff' }] },
            ],
        };

        map = new Map(document.getElementById('map'), mapOptions);

        setMarkers();
    }

    initMap();

    function setMarkers() {
        for (var i = 0; i < pins.length; i++) {
            var pin = pins[i],
                pos = new google.maps.LatLng(pin[2], pin[3]),
                title = pin[1];

            addMarker(i, pos, title);
        }
    }

    function addMarker(i, pos, title) {
        const marker = new google.maps.Marker({
            position: pos,
            map: map,
            animation: google.maps.Animation.DROP,
            icon: icon,
            title: title,
        });

        markers.push(marker);
    }

    locationItems.forEach(locationItem => {
        locationItem.addEventListener('mouseenter', () => {
            const pinId = locationItem.dataset.pin;
            markers[pinId].setAnimation(google.maps.Animation.BOUNCE);
        });

        locationItem.addEventListener('mouseleave', () => {
            const pinId = locationItem.dataset.pin;
            markers[pinId].setAnimation(null);
        })
    })
}
