import simpleParallax from 'simple-parallax-js';

var image = document.getElementsByClassName('footer__bg-img');

if (image) {
    new simpleParallax(image, {
        delay: 0.3,
        transition: 'cubic-bezier(0,0,0,1)',
        orientation: 'up',
    });
}
