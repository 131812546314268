const accordionWrapper = document.querySelector<HTMLElement>('.accordion__panes');

if (accordionWrapper) {
    const accordionLinks = document.querySelector<HTMLElement>('.accordion__sidebar_links');
    const accordionTriggers = Array.from(document.querySelectorAll<HTMLElement>('.accordion__sidebar_link'));
    const paneActiveClass = 'accordion__pane--active';
    const triggerActiveClass = 'accordion__sidebar_link--active';
    const initialPane = document.querySelector<HTMLElement>(`.${paneActiveClass}`);
    const initialPaneHeight = initialPane.getBoundingClientRect();

    accordionWrapper.style.height = `${initialPane.clientHeight + 480}px`;

    accordionTriggers.map((trigger: any) => {
        trigger.addEventListener('click', (e) => handleAccordionToggle(e, trigger));
    })

    const handleAccordionToggle = (evt, trigger) => {
        const target = evt.target.dataset.id;
        const paneToHide = document.querySelectorAll<HTMLElement>('.' + paneActiveClass);
        const paneToShow = document.getElementById(target);
        const triggerToDisable = document.querySelector('.' + triggerActiveClass);
        const newHeight = paneToShow.offsetHeight;

        accordionWrapper.style.height = `${newHeight}px`;
        paneToHide[0].classList.remove(paneActiveClass);
        triggerToDisable.classList.remove(triggerActiveClass);

        paneToShow.classList.add(paneActiveClass);
        trigger.classList.add(triggerActiveClass);
    };

    function offset(elt) {
        const rect = elt.getBoundingClientRect(),
            bodyElt = document.body;
        return {
            top: rect.top + bodyElt.scrollTop,
            left: rect.left + bodyElt.scrollLeft,
        };
    }

    const top = offset(accordionLinks).top - 30;

    window.addEventListener('scroll', function () {
        const y = document.scrollingElement.scrollTop;
        if (y > top) {
            accordionLinks.classList.add('accordion__sidebar_links--fixed');
        } else {
            accordionLinks.classList.remove('accordion__sidebar_links--fixed');
        }
    });
}
