const popUp = document.querySelector('.pop-up__modal') as HTMLDivElement;

if (popUp) {
    const closeModal = popUp.querySelector('.pop-up__modal-close') as HTMLDivElement;
    const modalBackdrop = popUp.querySelector('.pop-up__modal-backdrop') as HTMLDivElement;
    const displayConditionsAny = popUp.dataset.displayConditionsAny.split(',').filter(s => s);
    const displayConditionsRequired = popUp.dataset.displayConditionsRequired.split(',').filter(s => s);
    const linkButton = popUp.querySelector('a');
    const popUpCloseEndpoint = popUp.dataset.closeEndpoint;
    const popUpId = popUp.dataset.popupId;
    const popUpDaysUntilShowAgain = Number(popUp.dataset.showAgainAfter);
    let canOpen = true;
    let hasOpened = false;
    let anyConditionsMet = 0;
    let requiredConditionsMet = 0;
    let afterSecondsCheckMet = false;
    let afterScrollCheckMet = false;
    let afterMouseLeaveCheckMet = false;

    const handleModalOpen = () => {
        if (canOpen) {
            hasOpened = true;
            popUp.style.opacity = '1';
            popUp.style.visibility = 'visible';
            document.body.classList.add('body--hidden');
            document.documentElement.style.overflowX = 'hidden';
            document.documentElement.style.overflowY = 'hidden';
        }
    };

    const handleModalClose = () => {
        popUp.style.opacity = '0';
        popUp.style.visibility = 'hidden';
        canOpen = false;
        fetch(popUpCloseEndpoint + `?popUpId=${popUpId}&daysUntilShowAgain=${popUpDaysUntilShowAgain}`, {
            method: 'POST',
        });
        document.body.classList.remove('body--hidden');
        document.documentElement.style.overflowX = 'hidden';
        document.documentElement.style.overflowY = 'auto';
    };

    if (closeModal) {
        closeModal.addEventListener('click', () => handleModalClose());
    }
    if (modalBackdrop) {
        modalBackdrop.addEventListener('click', () => handleModalClose());
    }
    if (linkButton) {
        linkButton.addEventListener('click', () => handleModalClose());
    }

    const startAfterSecondsConditionCheck = (isRequired) => {
        if (hasOpened || afterSecondsCheckMet) {
            return;
        }
        const secondsUntilOpen = Number(popUp.dataset.seconds);
        setTimeout(() => {
            isRequired ? requiredConditionsMet++ : anyConditionsMet++;
            afterSecondsCheckMet = true;
        }, secondsUntilOpen * 1000);
    }

    const startAfterScrollPercentageConditionCheck = (isRequired) => {
        if (hasOpened || afterScrollCheckMet) {
            return;
        }
        const scrollPercentageToOpen = Number(popUp.dataset.scrollPercentage);
        const triggerScrollHeight = (document.body.scrollHeight / 100) * scrollPercentageToOpen;
        document.addEventListener('scroll', () => {
            if (window.scrollY > triggerScrollHeight) {
                isRequired ? requiredConditionsMet++ : anyConditionsMet++;
                afterScrollCheckMet = true;
            }
        });
    }

    const startAfterMouseLeavesPageConditionCheck = (isRequired) => {
        document.addEventListener('mouseout', (ev) => {
            if (hasOpened || afterMouseLeaveCheckMet) {
                return;
            }
            var outNode = ev.relatedTarget as HTMLElement || ev.target as HTMLElement;
            let isExitNode = outNode.nodeName === 'HTML' 
                || outNode.classList.contains('header__top') 
                || outNode.classList.contains('header__main');
            if (isExitNode && ev.clientY < 1) {
                isRequired ? requiredConditionsMet++ : anyConditionsMet++;
                afterMouseLeaveCheckMet = true;
            }
        });
    }

    const startConditions = (displayConditions, isRequired) => {
        if (!displayConditions)
            return;
        displayConditions.forEach(displayCondition => {
            switch (displayCondition) {
                case 'AfterSeconds':
                    startAfterSecondsConditionCheck(isRequired)
                    break;
                case 'AfterScrollPercentage':
                    startAfterScrollPercentageConditionCheck(isRequired)
                    break;
                case 'AfterMouseLeavesPage':
                    startAfterMouseLeavesPageConditionCheck(isRequired)
            }
        });
    }

    const checkIfConditionsMet = () => {
        let isRequiredConditionsMet = displayConditionsRequired.length === 0 
            || requiredConditionsMet >= displayConditionsRequired.length;

        let isAnyConditionsMet = displayConditionsAny.length === 0 || anyConditionsMet > 0;

        if (isRequiredConditionsMet && isAnyConditionsMet) {
            window.clearInterval(conditionsCheckInterval);
            handleModalOpen();
        }
    }

    startConditions(displayConditionsAny, false);
    startConditions(displayConditionsRequired, true);
    const conditionsCheckInterval = setInterval(checkIfConditionsMet, 150);
}
