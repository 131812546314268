import Swiper, { Scrollbar } from 'swiper';
Swiper.use([Scrollbar]);

const categoryListOptions = {
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        snapOnRelease: true,
        hide: false,
    },
    spaceBetween: 30,
    breakpoints: {
        0: {
            slidesPerView: 1
        },
        320: {
            slidesPerView: 1.5
        },
        640: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 5
        },
        1000: {
            slidesPerView: 6
        },
    },
};

const swiper = new Swiper('.category-list__grid.swiper', categoryListOptions);
