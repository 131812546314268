const hoverImageWrap = document.querySelector<HTMLElement>('.interactive-demo__image--hover');
const hoverImage = document.querySelector<HTMLImageElement>('.interactive-demo__image--hover img');
const hoverEls = Array.from(document.querySelectorAll<HTMLElement>('.interactive-demo__sidebar-item'));

const toggleImage = (newSrc) => {
    hoverImage.src = newSrc;
    hoverImageWrap.style.opacity = '1';
};

const resetImage = () => {
    hoverImageWrap.style.opacity = '0';
};

hoverEls.map((el) => {
    const newImage = el.dataset.image;

    el.addEventListener('mouseover', () => toggleImage(newImage));
    el.addEventListener('mouseout', () => resetImage());
});
