import Swiper, { Navigation } from 'swiper/core';
Swiper.use([Navigation]);

const carouselOptions = {
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1
};

const swiper = new Swiper('.case-studies__carousel', carouselOptions);
