const header = document.querySelector('.header');
const html = document.querySelector('html');
var isMenuOpen = false;

if (header) {
    const headerMain = document.querySelector('.header__main');

    window.addEventListener('scroll', function () {
        const y = document.scrollingElement.scrollTop;

        if (y > 40) {
            headerMain.classList.add('header__main--fixed');
        } else {
            headerMain.classList.remove('header__main--fixed');
        }
    });

    const searchTrigger = document.querySelector<HTMLImageElement>('.header__main-search-trigger');
    const searchWrapper = document.querySelector<HTMLImageElement>('.header__main-search');

    const toggleSearch = () => {
        searchTrigger.classList.toggle('header__main-search-trigger--active');
        searchWrapper.classList.toggle('header__main-search--active');
    };

    searchTrigger.addEventListener('click', toggleSearch);
    searchTrigger.addEventListener('keydown', (event: KeyboardEvent) => {
        if (event.isComposing || event.key === 'Enter') {
            toggleSearch()
        }
    });

    const menuSubItems = document.querySelectorAll<HTMLImageElement>('.header__main-menu__sub-li-secondary');
    const menuSubImage = document.querySelector<HTMLImageElement>('.header__main-menu__sub-image-img');
    const menuSubText = document.querySelector<HTMLImageElement>('.header__main-menu__sub-image-overlay');
    const menuItems = document.querySelectorAll('.header__main-menu_li-toggle');
    const menuSubSubItems = document.querySelectorAll('.header__main-menu__sub');

    const handleSubMenuHover = (e) => {
        const t = e.currentTarget;
        const img = t.dataset.img;
        const text = t.dataset.text;

        if (img) {
            menuSubImage.style.opacity = '0';
            setTimeout(() => {
                menuSubImage.src = img;
                menuSubImage.style.opacity = '1';
            }, 200);
        }

        if (text) {
            menuSubText.style.display = 'flex';
            menuSubText.innerHTML = text;
        }
    };

    menuSubItems.forEach((item) => item.addEventListener('mouseenter', handleSubMenuHover));
    menuSubItems.forEach((item) =>
        item.addEventListener('keydown', (event: KeyboardEvent) => {
            const subItem = item.querySelector('.header__main-menu__sub-links--sub');
            if (event.isComposing || event.key === 'Tab') {
                subItem.classList.add('header__main-menu__sub-sub--active');
            }
            if (event.isComposing || event.key === 'Escape') {
                if (subItem.classList.contains('header__main-menu__sub-sub--active')) {
                    subItem.classList.remove('header__main-menu__sub-sub--active');
                }
            }
        }),
    );

    const mobileMenuTrigger = document.querySelector<HTMLImageElement>('.header__main-trigger');
    const mobileMenuWrapper = document.querySelector<HTMLImageElement>('.header__mobile-wrapper');

    const handleMobileMenu = () => {
        mobileMenuTrigger.classList.toggle('header__main-trigger--active');
        mobileMenuWrapper.classList.toggle('header__mobile-wrapper--active');
        isMenuOpen = !isMenuOpen;
        if (isMenuOpen) {
            html.style.overflow = 'hidden';
        } else {
            html.style.overflow = 'visible';
        }
    };

    menuItems.forEach((item) =>
        item.addEventListener('keydown', (event: KeyboardEvent) => {
            if (item.nextElementSibling.classList.contains('header__main-menu__sub--alpha')) {
                const links = document.querySelectorAll('.header__main-menu__sub_sub-a');
                links.forEach((link) => {
                    link.setAttribute('tabindex', '0');
                });
            }

            if (item.nextElementSibling.classList.contains('header__main-menu__sub--beta')) {
                const links = document.querySelectorAll('.header__main-menu__sub-a');
                const inactiveLinks = document.querySelectorAll('.header__main-menu__sub_sub-a');
                links.forEach((link) => {
                    link.setAttribute('tabindex', '0');
                });
                inactiveLinks.forEach((link) => {
                    link.setAttribute('tabindex', '-1');
                });
            }

            if (event.isComposing || event.key === 'Enter') {
                const listItems = document.querySelectorAll('.header__main-menu__sub');
                listItems.forEach((listItem) => {
                    if (listItem.classList.contains('header__main-menu__sub--active')) {
                        listItem.classList.remove('header__main-menu__sub--active');
                    }
                });
                item.nextElementSibling.classList.add('header__main-menu__sub--active');
                const menuListItems = item.nextElementSibling.querySelectorAll('.header__main-menu__sub-li-primary');
                menuListItems.forEach((menuListItem) =>
                    menuListItem.addEventListener('keydown', (event: KeyboardEvent) => {
                        if (event.isComposing || event.key === 'Tab') {
                            const menuListItemSubs = menuListItem.querySelectorAll('.header__main-menu__sub_sub');
                            menuListItemSubs.forEach((menuListItemSub) => {
                                if (menuListItemSub.classList.contains('header__main-menu__sub_sub--active')) {
                                    menuListItemSub.classList.remove('header__main-menu__sub_sub--active');
                                    const itemTabIndex = menuListItemSub.querySelectorAll(
                                        '.header__main-menu__sub_sub-a',
                                    );
                                    itemTabIndex.forEach((link) => {
                                        link.setAttribute('tabindex', '-1');
                                    });
                                }
                                if (!menuListItemSub.classList.contains('header__main-menu__sub_sub')) {
                                    const itemTabIndex = menuListItemSub.querySelectorAll(
                                        '.header__main-menu__sub_sub-a',
                                    );
                                    itemTabIndex.forEach((link) => {
                                        link.setAttribute('tabindex', '-1');
                                    });
                                }
                                menuListItemSub.classList.add('header__main-menu__sub_sub--active');
                                if (menuListItemSub.classList.contains('header__main-menu__sub_sub')) {
                                    const itemTabIndex = menuListItemSub.querySelectorAll(
                                        '.header__main-menu__sub_sub-a',
                                    );
                                    itemTabIndex.forEach((link) => {
                                        link.setAttribute('tabindex', '-1');
                                    });
                                }
                                if (menuListItemSub.classList.contains('header__main-menu__sub_sub--active')) {
                                    const itemTabIndex = menuListItemSub.querySelectorAll(
                                        '.header__main-menu__sub_sub-a',
                                    );
                                    itemTabIndex.forEach((link) => {
                                        link.setAttribute('tabindex', '0');
                                    });
                                }
                            });
                        }
                        if (event.isComposing || event.key === 'Escape') {
                            const menuListItemSubs = menuListItem.querySelectorAll('.header__main-menu__sub_sub');
                            menuListItemSubs.forEach((menuListItemSub) =>
                                menuListItemSub.classList.remove('header__main-menu__sub_sub--active'),
                            );
                            if (item.nextElementSibling.classList.contains('header__main-menu__sub--alpha')) {
                                const links = document.querySelectorAll('.header__main-menu__sub_sub-a');
                                links.forEach((link) => {
                                    link.setAttribute('tabindex', '-1');
                                });
                            }
                            if (item.nextElementSibling.classList.contains('header__main-menu__sub--beta')) {
                                const links = document.querySelectorAll('.header__main-menu__sub-a');
                                links.forEach((link) => {
                                    link.setAttribute('tabindex', '-1');
                                });
                            }
                        }
                    }),
                );
            }
        }),
    );

    menuItems.forEach((item) =>
        item.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.isComposing || event.key === 'Escape') {
                item.nextElementSibling.classList.remove('header__main-menu__sub--active');
                if (item.nextElementSibling.classList.contains('header__main-menu__sub--alpha')) {
                    const links = document.querySelectorAll('.header__main-menu__sub_sub-a');
                    links.forEach((link) => {
                        link.setAttribute('tabindex', '-1');
                    });
                }

                if (item.nextElementSibling.classList.contains('header__main-menu__sub--beta')) {
                    const links = document.querySelectorAll('.header__main-menu__sub-a');
                    links.forEach((link) => {
                        link.setAttribute('tabindex', '-1');
                    });
                }
            }
        }),
    );

    menuSubSubItems.forEach((item) =>
        item.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.isComposing || event.key === 'Escape') {
                item.classList.remove('header__main-menu__sub--active');
            }
        }),
    );

    mobileMenuTrigger.addEventListener('click', handleMobileMenu);

    // Sub Menu Open Triggers

    const mobileSubMenuTriggers = Array.from(document.querySelectorAll<HTMLDivElement>('.header__mobile-a--trigger'));

    const handleMobileSubMenuOpen = (item) => item.nextElementSibling.classList.toggle('header__mobile-sub-ul--active');

    mobileSubMenuTriggers.forEach((item) => item.addEventListener('click', () => handleMobileSubMenuOpen(item)));

    // Sub Menu Back Triggers
    const mobileSubMenuBackTriggers = Array.from(
        document.querySelectorAll<HTMLDivElement>('.header__mobile-a--back-trigger'),
    );

    const handleMobileSubMenuClose = (item) => {
        const parent = item.parentNode;
        const grandParent = parent.parentNode;
        grandParent.classList.remove('header__mobile-sub-ul--active');
    };

    mobileSubMenuBackTriggers.forEach((item) => item.addEventListener('click', () => handleMobileSubMenuClose(item)));
}
